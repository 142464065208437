import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

const LinkToExternalSource = ({ children, href }) => {
  return (
    <a
      className="link-to-external-source"
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      {children} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </a>
  );
};

export default LinkToExternalSource;
