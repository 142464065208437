import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import Landing from "./landing/Landing";
import BlogPost from "./blogPost/BlogPost";
import ErrorMessage from "./errorMessage/ErrorMessage";
import { blogPosts } from "../signals/blogPostsSignal";

const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    errorElement: <ErrorMessage />,
    children: [
      {
        path: "/",
        element: <Landing />,
        errorElement: <ErrorMessage />,
      },
      {
        path: "/blog",
        element: <BlogPost />,
        errorElement: <ErrorMessage />,
        children: blogPosts.value.map((bp) => ({
          path: "/blog/" + bp.formattedTitle,
          element: <bp.element />,
          errorElement: <ErrorMessage />,
        })),
      },
    ],
  },
]);

export default router;
