import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import Gif from "../gif/Gif";
import IMG_SRC from "../../assets/6/6.webp";
import FYROX_EDITOR_INITIAL_OPEN from "../../assets/5/fyrox-editor-initial-open.webp";
import FYROX_EDITOR_PHYSICS_2D_EXPANDED from "../../assets/6/fyrox-editor-physics-2d-expanded.webp";
import FYROX_EDITOR_CHILDREN_OF_RIGID_BODY from "../../assets/6/fyrox-editor-children-of-rigid-body.webp";
import FYROX_EDITOR_RIGID_BODY_NAMED from "../../assets/6/fyrox-editor-rigid-body-named.webp";
import FYROX_EDITOR_COLLIDER_RESIZED from "../../assets/6/fyrox-editor-collider-resized.webp";
import FYROX_EDITOR_RIGID_BODY_WITH_SCRIPT from "../../assets/6/fyrox-editor-rigid-body-with-script.webp";
import FYROX_EDITOR_REFERENCE_SQUARES from "../../assets/6/fyrox-editor-reference-squares.webp";
import FYROX_EXECUTOR_PLAYER_MOVING from "../../assets/6/fyrox-executor-player-moving.webm";
import { blogListObject as blog5 } from "./5";
import { blogListObject as blog7 } from "./7";
import PreviousNextPost from "../previousNextPost/PreviousNextPost";

const BlogPost = () => {
  return (
    <>
      Today we're going to build the player controller for our "Crowd Control"
      style game using the{" "}
      <LinkToExternalSource href="https://fyrox.rs/">
        Fyrox
      </LinkToExternalSource>{" "}
      game engine.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      Currently, when we run{" "}
      <code className="inline">{`> cargo run --package editor --release`}</code>
      &nbsp;we should see the following:
      <br />
      <img
        src={FYROX_EDITOR_INITIAL_OPEN}
        alt="Fyrox editor UI with Camera and Rectangle Node"
        className="reference"
      />
      <br />
      Click the "Create" button in the top toolbar, go down to "Physics 2D", and
      create a "Rigid Body" and a "Collider".
      <br />
      <img
        src={FYROX_EDITOR_PHYSICS_2D_EXPANDED}
        alt="Fyrox editor UI with Physics 2D dropdown expanded"
        className="reference"
      />
      <br />
      Now select the Collider 2D, Camera, and Sprite 2D nodes using Ctrl + Left
      Click on Windows, and drag them onto the Rigid Body 2D node, thus making
      them children of the Rigid Body.
      <br />
      <img
        src={FYROX_EDITOR_CHILDREN_OF_RIGID_BODY}
        alt="Fyrox editor UI with Collider 2D, Camera, and Sprite 2D as children of Rigid Body 2D"
        className="reference"
      />
      <br />
      Click on the Rigid Body 2D node in the node tree on the left and change
      the name of it in the node inspector panel on the right to "Player". Also,
      set the local scale to 0.5 for x, y, and z, and uncheck the box for "Can
      Sleep". If you don't turn off "Can Sleep", then your controller script
      will stop working. Finally, set the gravity scale to 0, or else the
      "Player" will just fall downward continuously.
      <img
        src={FYROX_EDITOR_RIGID_BODY_NAMED}
        alt="Fyrox editor UI changing properties of Rigid Body 2D node"
        className="reference"
      />
      <br />
      Now click on the Collider 2D node and resize the half extents property to
      0.25 by 0.25. This will give the collider a nice snug fit on our rescaled
      rigid body.
      <img
        src={FYROX_EDITOR_COLLIDER_RESIZED}
        alt="Fyrox editor UI changing properties of Collider 2D node"
        className="reference"
      />
      <br />
      With our "Player" created, we are ready to create the controller script.
      <br />
      To create a script, run the following command in your terminal:
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`> fyrox-template script --name "player" `}
      />
      In your folder structure you should now see{" "}
      <span className="italic">game/src/player.rs</span>. In this file you'll
      see a few things. First off, the "Player" struct holds data local to the
      "Player" script instance. Secondly, in the "ScriptTrait" implementation
      you'll see a few life-cycle methods. Fyrox does a good job of commenting
      the purpose of the{" "}
      <code className="inline">on_init, on_start, and on_deinit</code>
      &nbsp;methods. The <code className="inline">on_os_event</code>&nbsp;method
      is used to respond to events such as key presses, touch events, mouse
      events, and window events, among others. The{" "}
      <code className="inline">on_update</code>&nbsp;method is called at a
      steady rate of 60 times per second. In theory it is called each frame, but
      it can run multiple times per frame. Let's say your frame rate is 15, that
      means this method will be called 4 times per frame.
      <br />
      To track the directions in which we should be moving, add the following
      booleans to the "Player" struct:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`pub struct Player {
    move_left: bool,
    move_right: bool,
    move_up: bool,
    move_down: bool,
}`}
      />
      When the W, A, S, and D keys are pressed, we will set the various booleans
      to true, and when they aren't being pressed the booleans will be false. We
      need to listen for the key presses, so in our{" "}
      <code className="inline">on_os_event</code>&nbsp;method we will add the
      following code:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn on_os_event(&mut self, event: &Event<()>, context: &mut ScriptContext) {
    // Destructure the event object if the event is a WindowEvent
    if let Event::WindowEvent { event, .. } = event {

        // Destructure the WindowEvent if it is a KeyboardInput
        if let WindowEvent::KeyboardInput { event, .. } = event {

            // Check if the key is currently being pressed
            let pressed = event.state == ElementState::Pressed;

            // Check if the key being pressed/released is W, A, S, or D
            // Update state accordingly
            match event.physical_key {
                PhysicalKey::Code(KeyCode::KeyA) => self.move_left = pressed,
                PhysicalKey::Code(KeyCode::KeyD) => self.move_right = pressed,
                PhysicalKey::Code(KeyCode::KeyW) => self.move_up = pressed,
                PhysicalKey::Code(KeyCode::KeyS) => self.move_down = pressed,
                _ => {}
            }
        }
    }
}`}
      />
      Now that we are listening for keyboard events, we need to actually do
      something with the information.
      <br />
      In the <code className="inline">on_update</code>&nbsp;method add the
      following code to give the rigid body a linear velocity based on keyboard
      input:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn on_update(&mut self, context: &mut ScriptContext) {
    // Grab the rigid body component from the entity
    if let Some(rigid_body) = context.scene.graph[context.handle].cast_mut::<RigidBody>() {

        // Determine the x and y speed based on the state of the keyboard input
        let x_speed = match (self.move_left, self.move_right) {
            (true, false) => 3.0, // If the player is moving left, set the x speed to 3.0
            (false, true) => -3.0, // If the player is moving right, set the x speed to -3.0
            _ => 0.0, // If the player is not moving left or right, set the x speed to 0.0
        };
        let y_speed = match (self.move_up, self.move_down) {
            (true, false) => 3.0, // If the player is moving up, set the y speed to 3.0
            (false, true) => -3.0, // If the player is moving down, set the y speed to -3.0
            _ => 0.0, // If the player is not moving up or down, set the y speed to 0.0
        };
        
        // Set the linear velocity of the rigid body based on the state of the player
        rigid_body.set_lin_vel(Vector2::new(x_speed, y_speed));
    }
}`}
      />
      Scripts by themselves will not do anything. We need to attach it to our
      "Player" node in our scene.
      <br />
      In order to expose the player script in the editor, we need to import and
      register it. In the <span className="italic">game/src/lib.rs</span> file,
      import the player script:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`mod player;
use player::Player;`}
      />
      Then register it in the <code className="inline">register</code>
      &nbsp;method of the <code className="inline">PluginConstructor</code>
      &nbsp;trait implementation for the{" "}
      <code className="inline">GameConstructor</code>:<br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl PluginConstructor for GameConstructor {
    fn register(&self, context: PluginRegistrationContext) {
        // Register your scripts here.
        context
            .serialization_context
            .script_constructors
            .add::<Player>("Player");
    }

    ...
}`}
      />
      Restart your editor to update the list of accessible scripts. Click on the
      "Player" node, and add the "Player" script in the node inspector panel on
      the right:
      <img
        src={FYROX_EDITOR_RIGID_BODY_WITH_SCRIPT}
        alt="Fyrox editor UI adding Player script to Player node"
        className="reference"
      />
      <br />
      Let's add a couple squares flanking our player character for reference so
      we can see our movement. I've offset the squares by 1 in either direction
      on the x axis.
      <br />
      <img
        src={FYROX_EDITOR_REFERENCE_SQUARES}
        alt="Fyrox editor UI with two squares flanking the Player node for reference"
        className="reference"
      />
      <br />
      Hit the play button above the scene preview and start pressing the WASD
      keys. You should see your player node moving around as expected.
      <br />
      <Gif src={FYROX_EXECUTOR_PLAYER_MOVING} />
      <br />
      In the next post, we'll setup our game's terrain.
      <br />
      <div className="previous-next-container">
        <PreviousNextPost blogPost={blog5} />
        <PreviousNextPost blogPost={blog7} next />
      </div>
    </>
  );
};

export const blogListObject = {
  id: 6,
  title: "Game Development with Fyrox and Rust (Pt 2: Player Controller)",
  formattedTitle: "game-development-with-fyrox-and-rust-pt-2",
  tags: ["Rust", "Game Dev", "Fyrox"],
  description:
    "Learn how to setup a 2D player controller for a rigid body in the Fyrox game engine.",
  img: {
    src: IMG_SRC,
    alt: "Hands on a controller",
  },
  createdAt: new Date("2023-12-26 20:46:55.528931+00").toLocaleDateString(),
  repoUrl:
    "https://github.com/bocksdin/blog-fyrox-game-dev-tutorial/tree/player-controller",
  element: BlogPost,
};

export default BlogPost;
