import { Link } from "react-router-dom";
import { setSelectedblogPost } from "../../signals/blogPostsSignal";
import banner from "../../assets/Logo-Banner.webp";
import "./styles.css";

const PageHeader = () => {
  return (
    <header>
      <Link to="/" onClick={() => setSelectedblogPost(null)}>
        <img src={banner} alt="Bocksdin Coding Logo Banner" />
      </Link>
    </header>
  );
};

export default PageHeader;
