import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import PreviousNextPost from "../previousNextPost/PreviousNextPost";
import SectionTitle from "../sectionTitle/SectionTitle";
import Gif from "../gif/Gif";
import { blogListObject as blog9 } from "./9";
import IMG_SRC from "../../assets/10/10.webp";
import FYROX_STATIONARY_ENEMIES_SPAWNING from "../../assets/9/fyrox-stationary-enemies-spawning.webm";
import FYROX_PLAYER_HEALTHBAR from "../../assets/10/fyrox-player-healthbar.webp";
import FYROX_ENEMIES_MOVING_TOWARDS_PLAYER from "../../assets/10/fyrox-enemies-moving-towards-player-cropped.webm";
import FYROX_ENEMIES_ATTACKING_PLAYER from "../../assets/10/fyrox-enemies-attacking-player-cropped.webm";

const BlogPost = () => {
  return (
    <>
      Today we're going to add some bite to our enemies for our "Crowd Control"
      style game using the{" "}
      <LinkToExternalSource href="https://fyrox.rs/">
        Fyrox
      </LinkToExternalSource>{" "}
      game engine.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      Currently, when we run{" "}
      <code className="inline">{`> cargo run --package executor --release`}</code>
      &nbsp;we should see the following:
      <br />
      <Gif src={FYROX_STATIONARY_ENEMIES_SPAWNING} />
      It's pretty boring if our enemies just sit there. Let's have them
      continuously move towards the player and periodically attack.
      <SectionTitle text="Player HP" />
      In order to visualize the player's HP, we're going to add a healthbar
      above the player's head. In{" "}
      <span className="italic">src/scripts/player.rs</span>, add the following
      properties to the <code className="inline">Player</code> struct:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`pub struct Player {
  ...

  // Health Bar
  max_health: f32,
  health: f32,
  health_bar_background: Handle<Node>,
  health_bar_progress: Handle<Node>,
}`}
      />
      Add the following constants and method to the{" "}
      <code className="inline">Player</code> struct. This method will be used by
      external scripts to deal damage to the player:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl Player {
  pub const PLAYER_STARTING_HEALTH: f32 = 100.0;
  pub const MAX_HEALTH_BAR_WIDTH: f32 = 1.0;

  pub fn take_damage(&mut self, damage: &f32) {
      self.health -= damage;
  }
}`}
      />
      We create the healthbar using two rectangles, place them above the player,
      and add them as children of the player:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl ScriptTrait for Player {
  fn on_init(&mut self, context: &mut ScriptContext) {
      ...

      // Create the healthbar background
      let health_bar_background = RectangleBuilder::new(
          BaseBuilder::new()
              .with_name("HealthBarBackground")
              .with_local_transform(
                  TransformBuilder::new()
                      // Resize the healthbar so it is wide and short
                      .with_local_scale(Vector3::new(
                          Self::MAX_HEALTH_BAR_WIDTH,
                          Self::MAX_HEALTH_BAR_WIDTH / 4.,
                          1.,
                      ))
                      // Move the pivot center to the top left of the healthbar
                      // This is so it can be scaled from the left to the right
                      .with_scaling_pivot(Vector3::new(
                          Self::MAX_HEALTH_BAR_WIDTH / 2.,
                          Self::MAX_HEALTH_BAR_WIDTH / 4.,
                          1.,
                      ))
                      // Position the healthbar just above the player
                      .with_local_position(Vector3::new(0., 0.75, 0.01))
                      .build(),
              ),
      )
      // Gray color
      .with_color(Color::opaque(50, 50, 50))
      .build(&mut context.scene.graph);

      // Create the healthbar progress
      let health_bar_progress = RectangleBuilder::new(
          BaseBuilder::new()
              .with_name("HealthBarProgress")
              .with_local_transform(
                  TransformBuilder::new()
                      // Resize the healthbar so it is wide and short
                      .with_local_scale(Vector3::new(
                          Self::MAX_HEALTH_BAR_WIDTH,
                          Self::MAX_HEALTH_BAR_WIDTH / 4.,
                          1.,
                      ))
                      // Move the pivot center to the top left of the healthbar
                      // This is so it can be scaled from the left to the right
                      .with_scaling_pivot(Vector3::new(
                          Self::MAX_HEALTH_BAR_WIDTH / 2.,
                          Self::MAX_HEALTH_BAR_WIDTH / 4.,
                          1.,
                      ))
                      // Position the healthbar just above the player, and just in front of the background
                      .with_local_position(Vector3::new(0., 0.75, 0.))
                      .build(),
              ),
      )
      .with_color(Color::GREEN)
      .build(&mut context.scene.graph);

      // Make the healthbar background and progress child nodes of the player
      context
          .scene
          .graph
          .link_nodes(health_bar_background, context.handle);
      context
          .scene
          .graph
          .link_nodes(health_bar_progress, context.handle);

      // Set the Player struct properties
      self.max_health = Self::PLAYER_STARTING_HEALTH;
      self.health = Self::PLAYER_STARTING_HEALTH;
      self.health_bar_background = health_bar_background;
      self.health_bar_progress = health_bar_progress;
  }

  ...
}`}
      />
      Lastly, we need to make sure the healthbar is always displaying the
      current HP of the player. In our <code className="inline">on_update</code>{" "}
      method, we will update the healthbar if necessary:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl ScriptTrait for Player {
  ...

  fn on_update(&mut self, context: &mut ScriptContext) {
      ...
  
      // Grab the healthbar progess node
      let health_bar_progress = context.scene.graph[self.health_bar_progress].as_rectangle_mut();
  
      // Grab the healthbar progess node's transform
      let health_bar_transform = health_bar_progress.local_transform_mut();
  
      // Grab the current scale of the healthbar progess node
      let health_bar_scale = health_bar_transform.scale();
  
      // Calculate the new scale of the healthbar progess node
      // Don't let it go below 0 HP
      let new_health = f32::max(
          (self.health / self.max_health) * Self::MAX_HEALTH_BAR_WIDTH,
          0.0,
      );
  
      // If the new scale is different from the current scale, update the scale
      if health_bar_scale.x != new_health {
          health_bar_transform.set_scale(Vector3::new(
              new_health,
  
              // Don't change the y or z scale
              health_bar_scale.y,
              health_bar_scale.z,
          ));
      }
  }

  ...
}`}
      />
      Now, if we run{" "}
      <code className="inline">{`> cargo run --package executor --release`}</code>
      , we should see a green bar floating above the player:
      <br />
      <img
        src={FYROX_PLAYER_HEALTHBAR}
        alt="Player standing in center of screen with a green healthbar above them"
        className="reference"
      />
      <SectionTitle text="Enemy Movement" />
      Before the enemy can attack the player, it must get within striking
      distance. Continuous movement towards the player is rather simple. If we
      know the enemy's current position, and the player's current position, we
      just need to calculate the direction and apply a linear velocity to the
      enemy's rigid body. So, in our{" "}
      <span className="italic">src/scripts/enemy.rs</span> file, we add the
      following method:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl Enemy {
  fn move_towards_player(&mut self, graph: &mut Graph) {
    // Borrow the graph mutably
    let mut graph_ctx = graph.begin_multi_borrow::<2>();
    
    // Get *this* node instance
    let self_node = match graph_ctx.try_get(self.handle) {
        Some(node) => node,
        None => return,
    };

    // Get the player node
    let player_node = match graph_ctx.try_get(self.player_handle) {
        Some(node) => node,
        None => return,
    };

    // Get the rigid bodies of *this* node and the player node
    let self_rigid_body = match self_node.cast_mut::<RigidBody>() {
        Some(rigid_body) => rigid_body,
        None => return,
    };
    let player_rigid_body = match player_node.cast_mut::<RigidBody>() {
        Some(rigid_body) => rigid_body,
        None => return,
    };

    // Get the positions of *this* node and the player node
    let player_position = player_rigid_body.local_transform().position();
    let self_position = self_rigid_body.local_transform().position();

    // Calculate the direction vector from *this* node to the player node
    let dir_x = player_position.x - self_position.x;
    let dir_y = player_position.y - self_position.y;

    // Calculate the factor to scale the direction vector by
    // to ensure the enemy moves at the speed we want
    let factor = self.speed / (dir_x.powi(2) + dir_y.powi(2)).sqrt();

    // Set the linear velocity of *this* node to the scaled direction vector
    self_rigid_body.set_lin_vel(Vector2::new(dir_x * factor, dir_y * factor));
  }
}`}
      />
      Finally, we just need to call this function on each frame using the{" "}
      <code className="inline">on_update</code> method:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl ScriptTrait for Enemy {
  ...

  fn on_update(&mut self, context: &mut ScriptContext) {
    self.move_towards_player(&mut context.scene.graph);
  }

  ...
}`}
      />
      Now, when we run{" "}
      <code className="inline">{`> cargo run --package executor --release`}</code>
      , we see our enemies chasing after our protagonist.
      <Gif src={FYROX_ENEMIES_MOVING_TOWARDS_PLAYER} />
      <SectionTitle text="Enemy Attack" />
      Once the enemies are within striking distance of the player, they should
      deal damage. Attacking at a set interval is trivial with raycasting. In
      our <span className="italic">src/scripts/enemy.rs</span> file, we'll
      define another method called <code className="inline">attack_player</code>
      :<br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl Enemy {
  ...

  fn attack_player(&mut self, graph: &mut Graph) {
    // Get *this* node instance
    let self_node = match graph.try_get(self.handle) {
        Some(node) => node,
        None => return,
    };

    // Get the player node
    let player_node = match graph.try_get(self.player_handle) {
        Some(node) => node,
        None => return,
    };

    // Get the current position of *this* node and the player node
    let self_position = self_node.global_position();
    let player_position = player_node.global_position();
    
    // Calculate the direction vector from *this* node to the player node
    let direction = player_position - self_position;
    
    // Cast a ray from *this* node in the direction of the player node
    let mut buffer = Vec::<Intersection>::new();

    graph.physics2d.cast_ray(
        RayCastOptions {
            ray_origin: Point2::new(self_position.x, self_position.y),
            ray_direction: Vector2::new(direction.x, direction.y),
            max_len: 1.,
            groups: InteractionGroups::new(
                // Only collide with the player
                BitMask(0b1000_0000_0000_0000_0000_0000_0000_0000),
                BitMask(0b0111_1111_1111_1111_1111_1111_1111_1111),
            ),
            // Sort the results by distance
            sort_results: true,
        },
        // Store the collisions in the vector
        &mut buffer,
    );

    // If the ray hit the player, damage the player
    if buffer.len() > 0 && buffer[0].collider == self.player_collider {
        match graph.try_get_script_of_mut::<Player>(self.player_handle) {
            Some(script) => {
                script.take_damage(&self.attack_damage);
            }
            None => {}
        };
    }
  }
}`}
      />
      Finally, call the <code className="inline">attack_player</code> method at
      the set <code className="inline">attack_speed</code> property:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl ScriptTrait for Enemy {
  ...

  fn on_update(&mut self, context: &mut ScriptContext) {
    ...

    self.attack_timer += context.dt;
    if self.attack_timer >= self.attack_speed {
        self.attack_player(&mut context.scene.graph);
        self.attack_timer = 0.0;
    }
  }

  ...
}`}
      />
      Now when we run{" "}
      <code className="inline">{`> cargo run --package executor --release`}</code>
      , we should see the player's HP decrease when enemies get too close. Now
      that's more exciting!
      <Gif src={FYROX_ENEMIES_ATTACKING_PLAYER} />
      <br />
      <br />
      In the next post, we'll get the enemies animated using a spritesheet. Stay
      tuned!
      <br />
      <div className="previous-next-container">
        <PreviousNextPost blogPost={blog9} />
      </div>
    </>
  );
};

export const blogListObject = {
  id: 10,
  title:
    "Game Development with Fyrox and Rust (Pt 5: Enemy Movement and Attack)",
  formattedTitle: "game-development-with-fyrox-and-rust-pt-5",
  tags: ["Rust", "Game Dev", "Fyrox"],
  description:
    "Enemies should bring a bit of danger with them. We utilize rigid body velocity and raycasting to give the player something to run from.",
  img: {
    src: IMG_SRC,
    alt: "A pixel-art scene of a protagonist being chased by a horde of dangerous mushrooms.",
  },
  createdAt: new Date("2024-01-29 13:48:41.748808+00").toLocaleDateString(),
  repoUrl:
    "https://github.com/bocksdin/blog-fyrox-game-dev-tutorial/tree/enemy-movement-and-attack",
  element: BlogPost,
};

export default BlogPost;
