import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeSnippet = ({
  language,
  codeString,
  startingLineNumber,
  showLineNumbers = true,
}) => (
  <>
    <SyntaxHighlighter
      language={language}
      showLineNumbers={showLineNumbers}
      startingLineNumber={startingLineNumber}
      style={a11yDark}
    >
      {codeString}
    </SyntaxHighlighter>
    <br />
  </>
);

export default CodeSnippet;
