export const tags = [
  { value: "Rust", label: "Rust" },
  { value: "Actix Web", label: "Actix Web" },
  { value: "REST", label: "REST" },
  { value: "SQLx", label: "SQLx" },
  { value: "DB", label: "DB" },
  { value: "Auth", label: "Auth" },
  { value: "Fyrox", label: "Fyrox" },
  { value: "Game Dev", label: "Game Dev" },
];

export const tagColors = {
  Rust: "#FF5733",
  "Actix Web": "#DAF7A6",
  REST: "#FFC300",
  SQLx: "#00bf85",
  DB: "#97afd4",
  Auth: "#c0c0c0",
  Fyrox: "#ff644e",
  "Game Dev": "#7FC8F8",
};
