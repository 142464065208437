import { useCallback } from "react";
import { Link } from "react-router-dom";
import {
  setSelectedblogPost,
  toggleTriggerRecordView,
} from "../../signals/blogPostsSignal";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PreviousNextPost = ({ next = false, blogPost }) => {
  const onNavigation = useCallback(() => {
    setSelectedblogPost(blogPost);
    toggleTriggerRecordView();
  }, [blogPost]);

  return (
    <Link
      to={"../../blog/" + blogPost.formattedTitle}
      onClick={onNavigation}
      className={`${next ? "next" : "previous"}-post`}
    >
      {next ? (
        <span>
          Next Post <FontAwesomeIcon icon={faArrowRight} />
        </span>
      ) : (
        <span>
          <FontAwesomeIcon icon={faArrowLeft} /> Previous Post
        </span>
      )}
      <br />
      <span className="title">{blogPost.title}</span>
    </Link>
  );
};

export default PreviousNextPost;
