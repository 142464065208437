export const saveUser = () => {
  return fetch(process.env.REACT_APP_API + "users", { method: "POST" })
    .then((res) => res.json())
    .then((res) => res)
    .catch((error) => console.log(error));
};

export const recordBlogPostView = (token, id) => {
  return fetch(process.env.REACT_APP_API + "blog-posts/" + id + "/view", {
    method: "POST",
    headers: { Authorization: "Bearer " + token },
  }).catch((error) => console.log(error));
};
