import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";

const ThunderClientPlug = () => {
  return (
    <>
      <br />
      <br />
      <br />
      And with that, you're ready to give your new server a test drive with your
      favorite HTTP request method. I personally prefer using the VS Code
      extension{" "}
      <LinkToExternalSource href="https://www.thunderclient.com/">
        Thunder Client
      </LinkToExternalSource>
      .
    </>
  );
};

export default ThunderClientPlug;
