import { blogListObject as blog1 } from "./1";
import { blogListObject as blog2 } from "./2";
import { blogListObject as blog3 } from "./3";
import { blogListObject as blog4 } from "./4";
import { blogListObject as blog5 } from "./5";
import { blogListObject as blog6 } from "./6";
import { blogListObject as blog7 } from "./7";
import { blogListObject as blog8 } from "./8";
import { blogListObject as blog9 } from "./9";
import { blogListObject as blog10 } from "./10";

let value = [
  blog10,
  blog9,
  blog8,
  blog7,
  blog6,
  blog5,
  blog4,
  blog3,
  blog2,
  blog1,
].sort((a, b) => b.id - a.id);
export default value;
