import { Link } from "react-router-dom";
import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import { blogListObject as blog1 } from "./1";
import BASIC_EMAIL from "../../assets/8/basic_email.webp";
import BASIC_HTML from "../../assets/8/basic_html.webp";
import HTML_WITH_ATTACHMENTS from "../../assets/8/html_with_attachments.webp";
import MULTIPLE_RECIPIENTS from "../../assets/8/multiple_recipients.webp";
import IMG_SRC from "../../assets/8/8.webp";

const BlogPost = () => {
  return (
    <>
      Today we're going to send some emails using the{" "}
      <LinkToExternalSource href="https://docs.rs/lettre/latest/lettre/">
        lettre
      </LinkToExternalSource>{" "}
      crate.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      I'll be showing you how to send emails with a simple Rust program, but
      this same code can be used in any Rust application, such as an{" "}
      <Link to={"../../blog/" + blog1.formattedTitle}>Actix Web REST API</Link>.
      <br />
      <br />
      Let's dive right into it. First we create our project:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="> cargo new email-sender ; cd email-sender"
      />
      Dependencies: <code className="inline">dotenv</code> for accessing a{" "}
      <span className="italic">.env</span> file, and{" "}
      <code className="inline">lettre</code> for sending emails.
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="> cargo add dotenv lettre"
      />
      Open your <span className="italic">src/main.rs</span> file. There are
      three main steps we need to follow: construct the email, connect to our
      email provider's SMTP server, and send the email.
      <br />
      Firstly, we're going to create a reusable function that builds a
      connection to our email provider's SMTP server:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn create_mailer() -> SmtpTransport {
  // Get the username and password from the env file
  let username = std::env::var("EMAIL_USERNAME").expect("EMAIL_USERNAME not set");
  let password = std::env::var("EMAIL_PASSWORD").expect("EMAIL_PASSWORD not set");

  // Create the credentials
  let creds = Credentials::new(username, password);

  // Create a connection to our email provider
  // In this case, we are using Namecheap's Private Email
  // You can use any email provider you want
  SmtpTransport::relay("mail.privateemail.com")
      .unwrap()
      .credentials(creds)
      .build()
}`}
      />
      The username and password are the same credentials you use to access your
      email through gmail, outlook, yahoo, etc. You will also need to use their
      SMTP server address.
      <br />
      In this case, I will be sending emails through Namecheap's Private Email
      server. If you don't have an email account with them this SMTP server will
      not work for you.
      <br />
      Now for a simple example of building and sending an email:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn send_email() {
  // Build the email
  let email = Message::builder()
      .from("contact@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .to("questions@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .subject("Test Email")
      .body("Hello, this is a test email!".to_string())
      .unwrap();

  let mailer = create_mailer();

  // Send the email
  match mailer.send(&email) {
      Ok(_) => println!("Basic email sent!"),
      Err(error) => {
          println!("Basic email failed to send. {:?}", error);
      }
  }
}

fn main() {
  dotenv::dotenv().ok();
  println!("Sending emails...");
  
  send_email();
  
  println!("Emails sent!");
}`}
      />
      Run the program with <code className="inline">{"> cargo r -r"}</code> and
      if you've set it up correctly, you should receive an email that looks like
      this:
      <br />
      <img
        src={BASIC_EMAIL}
        alt={`Basic email with subject and body sent to questions@bocksdincoding.com from contacts@bocksdincoding.com`}
        className="reference small"
      />
      <br />
      Now, most emails sent programmatically contain HTML. Trying right now, if
      you were to update the body param to be an h1 tag or something, it would
      be rendered as plain text instead of an HTML element.
      <br />
      In order to achieve HTML rendered in your email, you must set the
      content-type header to HTML:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn send_email_with_html() {
  let email = Message::builder()
      .from("contact@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .to("questions@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .subject("Test Email")
      .header(ContentType::TEXT_HTML)
      .body(
          "<h1>Hello, this is a test email!</h1>
          <p>This is additional context.</p>
          <a href=\\"https://bocksdincoding.com\\">Check out my blog!</a>"
              .to_string(),
      )
      .unwrap();

  let mailer = create_mailer();

  match mailer.send(&email) {
      Ok(_) => println!("Email with HTML sent!"),
      Err(error) => {
          println!("Email with HTML failed to send. {:?}", error);
      }
  }
}

fn main() {
  dotenv::dotenv().ok();
  println!("Sending emails...");
  
  // send_email();
  send_email_with_html();
  
  println!("Emails sent!");
}`}
      />
      Your sent email should now look something like this:
      <br />
      <img
        src={BASIC_HTML}
        alt="Basic email with HTML rendered correctly"
        className="reference small"
      />
      <br />
      This is fine for simple emails. If you want to embed an image or some
      other form of media within your email, it gets a little more complicated.
      You have to specify that attachments are being sent, such as an image, and
      that the HTML should have access to it.
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn send_email_with_attachments() {
  let image = std::fs::read("src/logo.png").unwrap();
  let image_body = Body::new(image);

  let email = Message::builder()
      .from("contact@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .to("questions@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .subject("Test Email")
      // This takes the place of the .body(...)
      .multipart(
          MultiPart::related()
              // This is our HTML body
              .singlepart(SinglePart::html(
                  "<img src=\\"cid:logo.png\\" height=50 width=50 />
              <h1>Hello, this is a test email!</h1>
              <p>This is additional context.</p>
              <a href=\\"https://bocksdincoding.com\\">Check out my blog!</a>"
                      .to_string(),
              ))
              // This is our media to be referenced in the HTML body
              .singlepart(
                  Attachment::new_inline(String::from("logo.png"))
                      .body(image_body, "image/png".parse().unwrap()),
              ),
      )
      .unwrap();

  let mailer = create_mailer();

  match mailer.send(&email) {
      Ok(_) => println!("Email with attachments sent!"),
      Err(error) => {
          println!("Email with attachments failed to send. {:?}", error);
      }
  }
}

fn main() {
    dotenv::dotenv().ok();
    println!("Sending emails...");
    
    // send_email();
    // send_email_with_html();
    send_email_with_attachments();
    
    println!("Emails sent!");
}`}
      />
      Our email now looks a bit spicier:
      <br />
      <img
        src={HTML_WITH_ATTACHMENTS}
        alt="HTML email displaying a png of the Bocksdin Coding logo in the body"
        className="reference small"
      />
      <br />
      Lastly, what if you need to send a bunch of emails all at once, perhaps
      from a mailing list stored in a database? I've got you covered:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`fn send_email_with_multiple_recipients() {
  let recipients = vec![
      // Simple email address
      "questions@bocksdincoding.com",
      // Email address with display name
      "Rory <rory@bocksdincoding.com>",
      // ...
  ];

  let mut email = Message::builder()
      .from("contact@bocksdincoding.com".parse::<Mailbox>().unwrap())
      .subject("Test Email");

  for recipient in recipients {
      email = email.to(recipient.parse::<Mailbox>().unwrap());
      // email = email.cc(recipient.parse::<Mailbox>().unwrap());
      // email = email.bcc(recipient.parse::<Mailbox>().unwrap());
  }

  let email = email
      .body("Hello, this is a test email!".to_string())
      .unwrap();

  let mailer = create_mailer();

  match mailer.send(&email) {
      Ok(_) => println!("Email with multiple recipients sent!"),
      Err(error) => {
          println!("Email with multiple recipients failed to send. {:?}", error);
      }
  }
}

fn main() {
  dotenv::dotenv().ok();
  println!("Sending emails...");
  
  // send_email();
  // send_email_with_html();
  // send_email_with_attachments();
  send_email_with_multiple_recipients();

  println!("Emails sent!");
}`}
      />
      Boom! Email marketing campaign handled!
      <br />
      <img
        src={MULTIPLE_RECIPIENTS}
        alt="Basic email with multiple recipients"
        className="reference small"
      />
      <br />A parting note: emails may go to spam, but it depends on your
      recipient's email account. My gmail and work email accounts received them
      in the inbox, but my outlook sent it to spam.
    </>
  );
};

export const blogListObject = {
  id: 8,
  title: "Sending emails with Rust",
  formattedTitle: "sending-emails-with-rust",
  tags: ["Rust"],
  description:
    "There are many reasons one may need to send an email, such as notifying followers of new content! Learn how easy it is with Rust and the lettre crate.",
  img: {
    src: IMG_SRC,
    alt: "A person sitting at a computer, writing a program to send emails. The environment shows a typical programmer's workspace, complete with tech gadgets and programming books. The computer screen in the images displays lines of code.",
  },
  createdAt: new Date("2024-01-12 03:51:23.527595+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-rust-emails",
  element: BlogPost,
};

export default BlogPost;
