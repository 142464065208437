import Select from "react-select";
import { tags, tagColors } from "../../constants";
import {
  filteredBlogPosts,
  setTagsToFilterBy,
  tagsToFilterBy,
} from "../../signals/blogPostsSignal";
import BlogPostCard from "../blogPostCard/BlogPostCard";
import "./styles.css";

const colourStyles = {
  control: (styles) => ({ ...styles, width: "90%", margin: "15px auto 0" }),
  menu: (styles) => ({ ...styles, width: "90%", left: "5%" }),
  multiValue: (styles, { data }) => {
    const color = tagColors[data.value];
    return {
      ...styles,
      background: color,
      border: "1px solid black",
      fontWeight: 600,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles) => {
    return {
      ...styles,
      ":hover": {
        color: "white",
      },
    };
  },
};

const Landing = () => {
  return (
    <>
      <Select
        options={tags}
        isMulti
        onChange={setTagsToFilterBy}
        value={tagsToFilterBy.value}
        closeMenuOnSelect={false}
        styles={colourStyles}
      />
      <section className="landing landing-blog-posts">
        {filteredBlogPosts.value.map((blogPost) => (
          <BlogPostCard key={blogPost.id} blogPost={blogPost} />
        ))}
      </section>
    </>
  );
};

export default Landing;
