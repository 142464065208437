import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import PreviousNextPost from "../previousNextPost/PreviousNextPost";
import Gif from "../gif/Gif";
import { blogListObject as blog6 } from "./6";
import { blogListObject as blog9 } from "./9";
import IMG_SRC from "../../assets/7/7.webp";
import FYROX_EXECUTOR_PLAYER_MOVING from "../../assets/6/fyrox-executor-player-moving.webm";
import FYROX_EXECUTOR_PLAYER_WITH_MAP from "../../assets/7/fyrox-executor-player-with-map.webm";

const BlogPost = () => {
  return (
    <>
      Today we're going to build the terrain for our "Crowd Control" style game
      using the{" "}
      <LinkToExternalSource href="https://fyrox.rs/">
        Fyrox
      </LinkToExternalSource>{" "}
      game engine.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      Currently, when we run{" "}
      <code className="inline">{`> cargo run --package executor --release`}</code>
      &nbsp;we should see the following:
      <br />
      <Gif src={FYROX_EXECUTOR_PLAYER_MOVING} />
      <br />
      Before we begin, we can remove the two squares we used as reference to see
      our movement controller working.
      <br />
      <br />
      The first step we're going to take is make a file to hold a few constant
      variables. Create the{" "}
      <span className="italic">game/src/constants.rs</span> file and add the
      following code:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`// HEIGHT AND WIDTH
pub const MAP_SIZE: i32 = 10;

// COORDINATE OFFSET IN X AND Y DIRECTIONS
pub const MAP_OFFSET: i32 = 1000;

// MAXIMUM AND MINIMUM X AND Y VALUES
pub const MAX_MAP_XY: i32 = MAP_SIZE / 2;
pub const MIN_MAP_XY: i32 = -(MAP_SIZE / 2);
`}
      />
      These values will help us create a square map. &nbsp;
      <code className="inline">MAP_SIZE</code> specifies the side length, so in
      this case a 10x10 square. &nbsp;<code className="inline">MAP_OFFSET</code>{" "}
      is the coordinate offset in the x and y directions; so our game will be
      centered at{" "}
      <code className="inline">{"{ x: 1000.0, y: 1000.0, z: 0.0 }"}</code>. This
      offset is currently required to prevent an initial render desync when
      nodes are dynamically added to the game instance. This causes nodes to
      appear to fly out of global point{" "}
      <code className="inline">{"{ x: 0.0, y: 0.0, z: 0.0 }"}</code> to their
      destined locations, however, it isn't noticeable when far away from the
      origin. Finally, we are calculating the minimum and maximum x and y
      values, since they are unchanging and used multiple times. For instance,
      our 10x10 square is located at{" "}
      <code className="inline">{"{ x: 1000.0, y: 1000.0, z: 0.0 }"}</code>, so{" "}
      <code className="inline">MAX_MAP_XY = 5</code> (placed at 1005.0) and{" "}
      <code className="inline">MIN_MAP_XY = -5</code> (placed at 995.0).
      <br />
      <br />
      Next, let's center the existing player on the offset origin point. First,
      declare the constants module in{" "}
      <span className="italic">game/src/lib.rs</span>:<br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`mod constants;
use constants::{MAP_OFFSET, MAX_MAP_XY, MIN_MAP_XY};`}
      />
      Then, in <span className="italic">game/src/player.rs</span>:
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`use crate::constants::MAP_OFFSET;

...

impl ScriptTrait for Player {
  fn on_init(&mut self, context: &mut ScriptContext) {
      // Put initialization logic here.

      // Set the position of the player to the center of the offset map
      context.scene.graph[context.handle]
          .cast_mut::<RigidBody>()
          .unwrap()
          .local_transform_mut()
          .set_position(Vector3::new(MAP_OFFSET as f32, MAP_OFFSET as f32, 0.0));
  }

  ...
}`}
      />
      The player node will now be centered at{" "}
      <code className="inline">{"{ x: 1000.0, y: 1000.0, z: 0.0 }"}</code>.
      <br />
      <br />
      Time for the meat and potatoes. I will be using a couple spritesheets I
      purchased from itch.io at{" "}
      <LinkToExternalSource
        children="https://cainos.itch.io/pixel-art-top-down-basic"
        href="https://cainos.itch.io/pixel-art-top-down-basic"
      />
      . Once you have your desired spritesheets, place them in the{" "}
      <span className="italic">data</span> folder at the root level of your
      project.
      <br />
      <br />
      We'll be implementing the map generation in the{" "}
      <span className="italic">game/src/lib.rs</span> file. The primary
      structure for the square map generation should be fairly straightforward:{" "}
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl Game {
  ...

  pub fn build_tilemap(&mut self, graph: &mut Graph, resource_manager: &ResourceManager) {

      // Load textures once for reuse
      let grass_texture = resource_manager.request::<Texture, _>("data/grass_tileset.png");
      let stone_texture = resource_manager.request::<Texture, _>("data/stone_tileset.png");

      // Build tilemap in x and y directions
      // Add 1 to the max and min values to account for the boundary
      for x in MIN_MAP_XY - 1..=MAX_MAP_XY + 1 {
          for y in MIN_MAP_XY - 1..=MAX_MAP_XY + 1 {

              // Determine x and y position of current tile
              let tile_position = ((x + MAP_OFFSET), (y + MAP_OFFSET));

              // Build positional transform for tile
              let rb_transform = TransformBuilder::new()
                  .with_local_position(Vector3::new(
                      tile_position.0 as f32,
                      tile_position.1 as f32,
                      1.0,
                  ))
                  .build();

              if x.abs() == MAX_MAP_XY + 1 || y.abs() == MAX_MAP_XY + 1 {
                
                // If the tile is a boundary, build a stone tile
              
              } else {
                  
                // Otherwise, build a grass tile
              
              }
          }
      }
  }
}`}
      />
      First we load the textures before the loops start; it is faster to load
      once and clone than to load for each use.
      <br />
      Next, we loop in the x and y directions to create a square that is 10
      tiles wide by 10 tiles tall.
      <br />
      Then we store the global position of the current tile in{" "}
      <code className="inline">tile_position</code> for reuse.
      <br />
      Then we build the transform (position, scale, rotation, etc.) for the
      current tile using the global position we just stored.
      <br />
      Finally, we will determine which tile we are currently building, and then
      build it. If the tile is located at x or y position 6 or -6, then it is a
      boundary tile. Otherwise, it's a inner area tile.
      <br />
      <br />
      For the boundary tiles, we'll use a 2D rigid body with a collider. This
      will prevent the player from traversing past it.
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`// If the tile is a boundary, build a stone tile

// Build a 2D rigid body with a collider and a stone tile sprite
RigidBodyBuilder::new(
    BaseBuilder::new()
        .with_children(&[
            // Collider to prevent player from moving past boundary
            ColliderBuilder::new(BaseBuilder::new()).build(graph),
            // Stone tile sprite
            RectangleBuilder::new(BaseBuilder::new())
                .with_texture(stone_texture.clone())
                // Sprite is located in top left corner of sprite sheet
                // Sprite is 96px wide and 96px tall (aka, 37.5% of 256px)
                .with_uv_rect(Rect::new(0.0, 0.0, 0.375, 0.375))
                .build(graph),
        ])
        // Optional, set name of tile
        .with_name(format!("Boundary ({x}, {y})",))
        // Set position of tile
        .with_local_transform(rb_transform),
)
// Turn off gravity for tile
.with_gravity_scale(0.)
// Set tile to be static and not rotate
.with_rotation_locked(true)
.with_body_type(RigidBodyType::Static)
.build(graph);`}
      />
      The spritesheets I'm using are 256px by 256px. The{" "}
      <code className="inline">with_uv_rect</code> method expects 4 values
      between 0 and 1.
      <br />
      The first value is the starting position of the sprite to use, offset from
      the left. In this case, I'm using 0% offset from the left side.
      <br />
      The second value is the starting position of the sprite to use, offset
      from the top. In this case, I'm using 0% offset from the top side.
      <br />
      The third value is the width of the sprite. In this case, I'm using 37.5%
      of the 256px width of the total spritesheet; this ends up being 96px.
      <br />
      Likewise, the fourth value is the height of the sprite. In this case, I'm
      using 37.5% of the 256px height of the total spritesheet; this ends up
      being 96px.
      <br />
      Make sure you turn off the gravity, lock the rotation, and make the rigid
      body static. If you don't do this, the tile will fall straight down the
      screen, or be pushed by the player when a collision occurs.
      <br />
      <br />
      Lastly, for the <code className="inline">build_tilemap</code> method, we
      need to create the inner tiles that the player "walks" on.
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`
// Otherwise, build a grass tile

// Spritesheet is 8x8, 32px tiles
// Select random texture in sprite sheet
// Select from top half of sprite sheet
let random_tile_x = thread_rng().gen_range(0..=8);
let random_tile_y = thread_rng().gen_range(0..=4);

// Account for floating point inaccuracy by multiplying by 10000
let accurate_x = random_tile_x * 10000;
let accurate_y = random_tile_y * 10000;

// Convert to f32 and divide by 80000 to get UV coordinates as percentage of full spritesheet
// Spritesheet is 8 tiles wide and 8 tiles tall
// 8 * 10000 = 80000
// Resulting coordinate value will be between 0 and 1, increments of 0.125
let random_x_coordinate = (accurate_x as f32) / 80000.;
let random_y_coordinate = (accurate_y as f32) / 80000.;

// Build a grass tile sprite
RectangleBuilder::new(
    BaseBuilder::new()
        // Optional, set name of tile
        .with_name(format!("Tile ({x}, {y})",))
        // Set position of tile
        .with_local_transform(rb_transform),
)
.with_texture(grass_texture.clone())
// Sprite is 32px wide and 32px tall (aka, 12.5% of 256px)
.with_uv_rect(Rect::new(
    random_x_coordinate,
    random_y_coordinate,
    0.125,
    0.125,
))
.build(graph);`}
      />
      Here, we are adding some variability to the specific sprite chosen per
      tile. This will make the map randomized on each game initial load.
      <br />
      The spritesheet being used here is also 256px by 256px (32px sprites
      arranged in an 8x8 grid), but we only want to utilize sprites in the top
      half of the sheet.
      <br />
      For the starting left offset of our sprite sheet, we generate a random
      number between 0 and 8. Likewise, for the starting top offset, we generate
      a random number between 0 and 4.
      <br />
      We account for some floating point number finicky-ness by multiplying the
      randomly generated numbers by 10,000. The resulting percentages used for
      the UV rect will be precise to the ten-thousandths place.
      <br />
      Since the sprite sheet is an 8x8 grid of 32px sprites, we then divide the
      randomly generated numbers by 80,000 to get the percentage of the
      width/height the particular sprite starts.
      <br />
      Finally, we use a basic 2D rectangle node to display our ground tile at
      the specified global x and y position.
      <br />
      <br />
      Let's call the <code className="inline">build_tilemap</code> method once
      the scene loads and fire up the game!
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`impl Plugin for Game {

  ...
  
  fn on_scene_loaded(
    &mut self,
    _path: &Path,
    scene: Handle<Scene>,
    _data: &[u8],
    context: &mut PluginContext,
  ) {
      self.scene = scene;

      let graph: &mut Graph = &mut context.scenes[self.scene].graph;
      let resource_manager: &ResourceManager = &context.resource_manager;

      // Build Tilemap
      self.build_tilemap(graph, resource_manager);
  }
}`}
      />
      When running the game you should see something similar to this:
      <br />
      <Gif src={FYROX_EXECUTOR_PLAYER_WITH_MAP} />
      <br />
      That does it for terrain generation for this game. If you use different
      spritesheets, you may have to play around with the UV rects, but you'll
      get the hang of it soon enough.
      <br />
      <br />
      In the next post we'll tackle enemy generation. Stay tuned!
      <br />
      <div className="previous-next-container">
        <PreviousNextPost blogPost={blog6} />
        <PreviousNextPost blogPost={blog9} next />
      </div>
    </>
  );
};

export const blogListObject = {
  id: 7,
  title: "Game Development with Fyrox and Rust (Pt 3: Game Terrain)",
  formattedTitle: "game-development-with-fyrox-and-rust-pt-3",
  tags: ["Rust", "Game Dev", "Fyrox"],
  description:
    "2D terrain generation is a breeze with Fyrox. Using spritesheets and dynamic terrain generation, we build an arena for our game.",
  img: {
    src: IMG_SRC,
    alt: "Grassy terrain featuring ruins and trees in a pixel-art style.",
  },
  createdAt: new Date("2024-01-03 00:36:51.050837+00").toLocaleDateString(),
  repoUrl:
    "https://github.com/bocksdin/blog-fyrox-game-dev-tutorial/tree/game-terrain",
  element: BlogPost,
};

export default BlogPost;
