import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import FYROX_EDITOR_INITIAL_OPEN from "../../assets/5/fyrox-editor-initial-open.webp";
import FYROX_EXECUTOR_INITIAL_OPEN from "../../assets/5/fyrox-executor-initial-open.webp";
import IMG_SRC from "../../assets/5/5.webp";
import PreviousNextPost from "../previousNextPost/PreviousNextPost";
import { blogListObject as blog6 } from "./6";

const BlogPost = () => {
  return (
    <>
      Today we're going to build a "Crowd Control" style game using the{" "}
      <LinkToExternalSource href="https://fyrox.rs/">
        Fyrox
      </LinkToExternalSource>{" "}
      game engine.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      The first thing we need is to install Fyrox. Run the following in your
      command line:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={"> cargo install fyrox-template "}
      />
      The{" "}
      <LinkToExternalSource href="https://lib.rs/crates/fyrox-template">
        fyrox-template
      </LinkToExternalSource>{" "}
      crate allows us to easily create new projects and scripts.
      <br />
      Now, to create a new project, run the following in your command line:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={"> fyrox-template init --name crowd_control --style 2d "}
      />
      This will create a new game project setup for a 2D game. Note that the
      only difference between 2D and 3D here is that the camera uses an
      orthographic view. Other than that, 2D and 3D are the same.
      <br />
      <br />
      Next, let's pull up the Fyrox editor:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`> cd crowd_control
> cargo run --package editor --release`}
      />
      The first compile will take a couple minutes, but should be faster in
      subsequent builds. Once it's done compiling, you should see something like
      this:
      <br />
      <img
        src={FYROX_EDITOR_INITIAL_OPEN}
        alt="Fyrox editor UI"
        className="reference"
      />
      <br />
      Press the green "play" button at the top of the scene view. This will open
      a small window with your game running in it. You can also open this window
      via the command line with the following command:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="> cargo run --package executor --release"
      />
      You should see a small window with a light blue background and a gray
      square in the middle.
      <br />
      <img
        src={FYROX_EXECUTOR_INITIAL_OPEN}
        alt="Initial crowd control game window"
        className="reference"
      />
      <br />
      Currently the window title is "Fyrox Game". We can configure the window
      generated, so let's change the title.
      <br />
      Close the game window and open the{" "}
      <span className="italic">executor/src/main.rs</span> file. Replace the{" "}
      <code className="inline">executor</code> variable declaration and
      initialization with the following code to allow customization of the game
      window:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={6}
        codeString={`let mut window_attributes = WindowAttributes::default();
window_attributes.title = "Crowd Control".to_string();
let mut executor = Executor::from_params(
    EventLoop::new().unwrap(),
    GraphicsContextParams {
        window_attributes,
        vsync: false,
    },
);`}
      />
      Feel free to set the title to anything you want.
      <br />
      Our Fyrox project is up and running. In the next post, we'll get the
      player controller setup.
      <br />
      <div className="previous-next-container">
        <PreviousNextPost blogPost={blog6} next />
      </div>
    </>
  );
};

export const blogListObject = {
  id: 5,
  title: "Game Development with Fyrox and Rust (Pt 1: Project Setup)",
  formattedTitle: "game-development-with-fyrox-and-rust-pt-1",
  tags: ["Rust", "Game Dev", "Fyrox"],
  description:
    "Learn how to setup a project with the Fyrox game engine and embark on a game development journey using Rust.",
  img: {
    src: IMG_SRC,
    alt: "2D RPG-style buildings connected by a wooden bridge in a deserted, destroyed town.",
  },
  createdAt: new Date("2023-12-22 19:21:58.648327+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-fyrox-game-dev-tutorial",
  element: BlogPost,
};

export default BlogPost;
