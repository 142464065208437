import { useMemo } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

const SectionTitle = ({ text }) => {
  const span = useRef(null);
  const id = useMemo(() => text.replaceAll(" ", "-").toLowerCase(), [text]);
  const to = useMemo(
    () => window.location.origin + window.location.pathname + "#" + id,
    [id],
  );
  const onClick = useCallback(() => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    span.current.className = "bg-yellow revealed";
    setTimeout(() => {
      span.current.className = "bg-yellow";
    }, 1000);
    navigator.clipboard.writeText(window.location.href);
  }, [id]);
  return (
    <h2 id={id} className="section-title">
      <Link to={to} onClick={onClick}>
        {text}
      </Link>
      <FontAwesomeIcon icon={faLink} />
      <span ref={span} className="bg-yellow">
        Copied!
      </span>
    </h2>
  );
};

export default SectionTitle;
