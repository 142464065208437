import { Outlet } from "react-router-dom";
import PageFooter from "./components/pageFooter/PageFooter";
import PageHeader from "./components/pageHeader/PageHeader";
import { recordBlogPostView, saveUser } from "./utils/services";
import { token, setToken } from "./signals/tokenSignal";
import {
  filteredBlogPosts,
  selectedBlogPost,
  setSelectedblogPost,
  toggleTriggerRecordView,
  triggerRecordView,
} from "./signals/blogPostsSignal";
import { useEffect } from "react";

let savedToken = localStorage.getItem("token");
if (!savedToken) {
  saveUser().then((res) => {
    localStorage.setItem("token", res);
    setToken(res);
  });
} else {
  setToken(savedToken);
}

let formattedTitle = window.location.pathname.match(
  /(?<=\/blog\/).*((?=\/)|$)/,
);
if (formattedTitle) {
  let blogPost = filteredBlogPosts.value.find(
    (bp) => bp.formattedTitle === formattedTitle[0],
  );
  if (blogPost) {
    setSelectedblogPost(blogPost);
    toggleTriggerRecordView();
  }
}

function App() {
  useEffect(() => {
    if (triggerRecordView.value && token.value) {
      toggleTriggerRecordView();
      recordBlogPostView(token.value, selectedBlogPost.value.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBlogPost.value, triggerRecordView.value, token.value]);

  return (
    <main>
      <PageHeader />
      <Outlet />
      <PageFooter />
    </main>
  );
}

export default App;
