import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { selectedBlogPost } from "../../signals/blogPostsSignal";
import Tag from "../tag/Tag";
import SignOff from "../signOff/SignOff";
import "./styles.css";

const BlogPost = () => {
  useEffect(() => {
    const focusedSection = window.location.hash;
    if (!!focusedSection) {
      let section = document.getElementById(focusedSection.slice(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line
  }, [selectedBlogPost.value]);

  return (
    <article className="blog-post-container">
      <h1 className="blog-post-title">{selectedBlogPost.value?.title}</h1>
      <section className="blog-post-tags">
        {selectedBlogPost.value?.tags.map((t) => (
          <Tag key={t} name={t} />
        ))}
      </section>
      <span className="blog-post-date">
        {selectedBlogPost.value?.createdAt}
      </span>
      <section className="blog-post-body">
        <Outlet />
        <SignOff repoUrl={selectedBlogPost.value?.repoUrl} />
      </section>
      <section className="blog-post-contact">
        Questions about the article:{" "}
        <a
          href={`mailto: questions@bocksdincoding.com?subject=Question About: ${selectedBlogPost.value?.title}`}
        >
          questions@bocksdincoding.com
        </a>
        <br />
        General inquiries:{" "}
        <a href="mailto: contact@bocksdincoding.com">
          contact@bocksdincoding.com
        </a>
      </section>
    </article>
  );
};

export default BlogPost;
