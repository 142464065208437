import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faLinkedin,
  faGithub,
  faMastodon,
  // faPatreon,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { useMemo } from "react";

const PageFooter = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer>
      <section>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://youtube.com/@BocksdinCoding"
        >
          <FontAwesomeIcon className="red" icon={faYoutube} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/rory-letteney/"
        >
          <FontAwesomeIcon className="blue" icon={faLinkedin} />
        </a>
        <a target="_blank" rel="noreferrer" href="https://github.com/bocksdin">
          <FontAwesomeIcon className="black" icon={faGithub} />
        </a>
        <a href="mailto: contact@bocksdincoding.com">
          <FontAwesomeIcon className="black" icon={faEnvelope} />
        </a>
        <a
          rel="me"
          target="_blank"
          href="https://mastodon.social/@bocksdin_coding"
        >
          <FontAwesomeIcon className="light-purple" icon={faMastodon} />
        </a>
        {/* <a
          target="_blank"
          rel="noreferrer"
          href="https://patreon.com/BocksdinCoding"
        >
          <FontAwesomeIcon className="green patreon" icon={faPatreon} />
        </a> */}
        <a href="https://ko-fi.com/Q5Q2FX9TI" target="_blank" rel="noreferrer">
          <img
            src="https://storage.ko-fi.com/cdn/kofi1.png?v=3"
            alt="Buy Me a Coffee at ko-fi.com/bocksdin_coding"
          />
        </a>
      </section>
      <span className="copyright">
        {" "}
        {year} &copy; Rory Letteney - All rights reserved.
      </span>
    </footer>
  );
};

export default PageFooter;
