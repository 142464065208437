import { useNavigate } from "react-router-dom";
import {
  setSelectedblogPost,
  toggleTriggerRecordView,
} from "../../signals/blogPostsSignal";
import Tag from "../tag/Tag";
import "./styles.css";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BlogPostCard = ({ blogPost }) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    setSelectedblogPost(blogPost);
    toggleTriggerRecordView();
    navigate("blog/" + blogPost.formattedTitle);
  }, [blogPost, navigate]);

  return (
    <article
      tabIndex="0"
      className="blog-post-card"
      onClick={handleNavigate}
      onKeyUp={(event) => {
        event.persist();
        if (event.code === "Enter") handleNavigate();
      }}
    >
      <img src={blogPost.img.src} alt={blogPost.img.alt} />
      <section className="information">
        <h2>{blogPost.title}</h2>
        <p>{blogPost.description}</p>
        <section>
          {blogPost.tags.map((t) => (
            <Tag key={t} name={t} />
          ))}
        </section>
        <span>{blogPost.createdAt}</span>
        <span className="decorated">
          Read More <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </section>
    </article>
  );
};

export default BlogPostCard;
