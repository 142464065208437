import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import IMG_SRC from "../../assets/2/2.webp";
import ThunderClientPlug from "../plugs/ThunderClient";

const BlogPost = () => {
  return (
    <>
      Today we're going to connect an existing{" "}
      <LinkToExternalSource href="https://actix.rs/">
        Actix Web
      </LinkToExternalSource>{" "}
      REST API and a{" "}
      <LinkToExternalSource href="https://www.postgresql.org/">
        Postgres
      </LinkToExternalSource>{" "}
      DB with the{" "}
      <LinkToExternalSource href="https://github.com/launchbadge/sqlx#readme">
        SQLx
      </LinkToExternalSource>{" "}
      Rust crate. This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />I will be using a basic Actix Web REST API I've built. Here is the
      source code:{" "}
      <LinkToExternalSource href="https://github.com/bocksdin/blog-basic-actix-web-api">
        https://github.com/bocksdin/blog-basic-actix-web-api
      </LinkToExternalSource>
      .<br />
      To learn how to setup an Actix Web REST API, check out my previous
      article:{" "}
      <LinkToExternalSource href="https://bocksdincoding.com/blog/fast-rest-api-rust-actix-web">
        Fast REST API with Rust and Actix Web
      </LinkToExternalSource>
      .<br />
      <br />
      Before we begin, make sure you have your DATABASE_URL set in your{" "}
      <span className="italic">.env</span> file. An example can be seen in the{" "}
      <span className="italic">.env.example</span> file.
      <br />
      <br />
      First we need to add our dependencies:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`> cargo add dotenv sqlx --features="sqlx/postgres, sqlx/runtime-async-std, sqlx/tls-rustls" `}
      />
      Now, in our <span className="italic">/src/main.rs</span> we need to create
      our Postgres connection pool and share it with our routes:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={2}
        codeString={`use dotenv::dotenv;
use sqlx::{postgres::PgPoolOptions, Pool, Postgres};`}
      />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={8}
        codeString={`// information shared with each route
struct AppState {
    db: Pool<Postgres>,
}`}
      />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={21}
        codeString={`// configure our API to utilize the .env file
dotenv().ok();

// retrieve the database url from the .env file
let database_url = std::env::var("DATABASE_URL").expect("DATABASE_URL must be set!");

// create a connection pool to the database
let pool = PgPoolOptions::new()
    .max_connections(5)
    .connect(&database_url)
    .await
    .expect("Error building a connection pool");`}
      />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={36}
        codeString={`// share the pool with each route
.app_data(Data::new(AppState { db: pool.clone() }))`}
      />
      In our <span className="italic">/src/todolist/services.rs</span> file we
      have a few routes defined:
      <br />
      <ul>
        <li>GET /todolist/entries</li>
        <li>POST /todolist/entries</li>
        <li>{`PUT /todolist/entries/{id}`}</li>
        <li>{`DELETE /todolist/entries/{id}`}</li>
      </ul>
      Before we can hook each route up to our Postgres database, we need to
      perform some setup.
      <br />
      First we need to make sure our database has the appropriate tables:
      <br />
      <CodeSnippet
        language="psql"
        showLineNumbers={false}
        codeString={`CREATE TABLE todolist_entries (
    id SERIAL PRIMARY KEY,
    title VARCHAR(255),
    complete BOOLEAN DEFAULT FALSE
);`}
      />
      Next, we need to allow deserialization of query results into our
      TodolistEntry struct location in{" "}
      <span className="italic">/src/todolist/models.rs</span>
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={2}
        codeString={`// FromRow allows deserialization of query results into Rust structs
        use sqlx::FromRow;`}
      />
      And add FromRow to our TodolistEntry derive macro list:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={5}
        codeString={`#[derive(Serialize, Deserialize, Clone, FromRow)]`}
      />
      Now it's time to hook our routes up. Open up{" "}
      <span className="italic">/src/todolist/services.rs</span>.<br />
      SQLx dependency import:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={4}
        codeString={`use sqlx;`}
      />
      GET /todolist/entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={6}
        codeString={`#[get("/todolist/entries")]
async fn get_entries(data: web::Data<AppState>) -> impl Responder {
    match sqlx::query_as::<_, TodolistEntry>("SELECT id, title, complete FROM todolist_entries")
        // return all matching rows
        .fetch_all(&data.db)
        .await
    {
        Ok(entries) => HttpResponse::Ok().json(entries),
        Err(_) => HttpResponse::InternalServerError().json("Error trying to get entries"),
    }
}`}
      />
      POST /todolist/entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={18}
        codeString={`#[post("/todolist/entries")]
async fn create_entry(
    data: web::Data<AppState>,
    body: web::Json<CreateEntryBody>,
) -> impl Responder {
    let param_obj = body.into_inner();

    match sqlx::query_as::<_, TodolistEntry>("INSERT INTO todolist_entries (title) VALUES ($1) RETURNING id, title, complete")
        // bind the param_obj.title value to the $1 argument
        .bind(&param_obj.title)
        // return one row
        .fetch_one(&data.db)
        .await
    {
        Ok(entry) => HttpResponse::Ok().json(entry),
        Err(_) => HttpResponse::InternalServerError().json("Error trying to create entry"),
    }
}`}
      />
      {`PUT /todolist/entries/{id}:`}
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={39}
        codeString={`#[put("/todolist/entries/{id}")]
async fn update_entry(
    data: web::Data<AppState>,
    path: web::Path<i32>,
    body: web::Json<CompleteEntryBody>,
) -> impl Responder {
    let id = path.into_inner();
    let param_obj = body.into_inner();

    match sqlx::query_as::<_, TodolistEntry>("UPDATE todolist_entries SET complete=$1 WHERE id=$2 RETURNING id, title, complete")
        // bind the param_obj.complete value to the $1 argument
        .bind(&param_obj.complete)
        // bind the id value to the $2 argument
        .bind(&id)
        // return the updated row
        .fetch_one(&data.db)
        .await
    {
        Ok(entry) => HttpResponse::Ok().json(entry),
        Err(_) => HttpResponse::InternalServerError().json("Error trying to update entry"),
    }
}`}
      />
      {`DELETE /todolist/entries/{id}:`}
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={62}
        codeString={`#[delete("/todolist/entries/{id}")]
async fn delete_entry(data: web::Data<AppState>, path: web::Path<i32>) -> impl Responder {
    let id = path.into_inner();

    match sqlx::query!(
        "DELETE FROM todolist_entries WHERE id=$1",
        // bind the id value to the $1 argument
        id
    )
        // execute the query without returning any information
        .execute(&data.db)
        .await
    {
        Ok(_) => HttpResponse::Ok().json("Successfully deleted entry"),
        Err(_) => HttpResponse::InternalServerError().json("Error trying to delete entry"),
    }
}`}
      />
      <ThunderClientPlug />
    </>
  );
};

export const blogListObject = {
  id: 2,
  title: "Connect your Rust REST API and DB with SQLx",
  formattedTitle: "connect-your-rust-rest-api-and-db-with-sqlx",
  tags: ["Rust", "Actix Web", "SQLx", "DB", "REST"].sort(),
  description:
    "Elevate your Rust REST API development by seamlessly integrating SQLx, unraveling the simplicity and effectiveness of connecting to and managing databases.",
  img: {
    src: IMG_SRC,
    alt: "A REST API server written with the rust programming language. Featuring a server processing many requests at once. The server is communicating with a Postgres Database.",
  },
  createdAt: new Date("2023-11-29 17:48:30.272037+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-rust-actix-web-sqlx",
  element: BlogPost,
};

export default BlogPost;
