import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import IMG_SRC from "../../assets/3/3.webp";
import ThunderClientPlug from "../plugs/ThunderClient";

const BlogPost = () => {
  return (
    <>
      Today we're going to add basic/bearer auth to an existing{" "}
      <LinkToExternalSource href="https://actix.rs/">
        Actix Web
      </LinkToExternalSource>{" "}
      REST API.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />I will be using a basic Actix Web REST API I've built. Here is the
      source code:{" "}
      <LinkToExternalSource href="https://github.com/bocksdin/blog-basic-actix-web-api">
        https://github.com/bocksdin/blog-basic-actix-web-api
      </LinkToExternalSource>
      .<br />
      To learn how to setup an Actix Web REST API, check out my previous
      article:{" "}
      <LinkToExternalSource href="https://bocksdincoding.com/blog/fast-rest-api-rust-actix-web">
        Fast REST API with Rust and Actix Web
      </LinkToExternalSource>
      .<br />
      <br />
      Setup our <span className="italic">.env</span> file with a{" "}
      <code className="inline">JWT_SECRET</code> and{" "}
      <code className="inline">HASH_SECRET</code>:<br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`JWT_SECRET=supersecretjwt
HASH_SECRET=supersecrethash`}
      />
      Add our dependencies:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`> cargo add actix-web-httpauth argonautica hmac jwt sha2 uuid --features="uuid/v4, uuid/serde" `}
      />
      Add our imports to our <span className="italic">src/main.rs</span>:<br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={3}
        codeString={`// Actix Web
use actix_web::{dev::ServiceRequest, error::Error, web, HttpMessage};

// Auth Extractors and Middleware
use actix_web_httpauth::{
    extractors::{
        bearer::{self, BearerAuth},
        AuthenticationError,
    },
    middleware::HttpAuthentication,
};

// Json Web Token
use jwt::VerifyWithKey;

// Password Hashing
use hmac::{Hmac, Mac};
use sha2::Sha256;

// Other
use serde::{Deserialize, Serialize};
use dotenv::dotenv;
use std::sync::Mutex;
use uuid::Uuid;`}
      />
      We will be creating new routes to handling user creation and login, so
      let's import those to complete our{" "}
      <span className="italic">src/main.rs</span> file:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={28}
        codeString={`mod auth;
use auth::{
    models::User,
    services::{basic_auth, create_user},
};`}
      />
      For the purposes of this tutorial we will just use a vector to store our
      users:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={37}
        codeString={`struct AppState {
  users: Mutex<Vec<User>>, // <- Mutex is necessary to mutate safely across threads
}`}
      />
      Now we can start the basic/bearer auth implementation.
      <br />
      Let's begin by defining the structure of the data carried by the JWT (json
      web tokens) we create:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={41}
        codeString={`// Structure of the data contained in the JWT
#[derive(Serialize, Deserialize, Clone)]
pub struct TokenClaims {
    id: Uuid,
}`}
      />
      We're keeping it simple for the purposes of this tutorial, but you can
      include any information your application requires.
      <br />
      <br />
      Now we need to define a middleware function that verifies that Bearer
      tokens sent in an Authorization request header are valid and trusted:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={47}
        codeString={`// Middleware for JWT validation
async fn validator(
    req: ServiceRequest,
    credentials: BearerAuth,
) -> Result<ServiceRequest, (Error, ServiceRequest)> {
    // Grab the JWT secret from the .env file
    let jwt_secret: String = std::env::var("JWT_SECRET").expect("JWT_SECRET must be set!");

    // Build key to verify integrity of the token
    let key: Hmac<Sha256> = Hmac::new_from_slice(jwt_secret.as_bytes()).unwrap();
    let token_string = credentials.token();

    // Verify the token
    let claims: Result<TokenClaims, &str> = token_string
        .verify_with_key(&key)
        .map_err(|_| "Invalid token");

    match claims {
        Ok(value) => {
            // If token is verified, continue the request chain
            req.extensions_mut().insert(value);
            Ok(req)
        }
        Err(_) => {
            // If token is not verified, return an error
            let config = req
                .app_data::<bearer::Config>()
                .cloned()
                .unwrap_or_default()
                .scope("");

            Err((AuthenticationError::from(config).into(), req))
        }
    }
}`}
      />
      Time to build our HttpServer utilizing the Bearer middleware:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={88}
        codeString={`// This tells our program to utilize the actix_web runtime
#[actix_web::main]
async fn main() -> std::io::Result<()> {
    dotenv().ok();
    HttpServer::new(move || {
        // Build middleware for JWT validation
        let bearer_middleware = HttpAuthentication::bearer(validator);

        App::new()
            .app_data(Data::new(AppState {
                users: Mutex::new(vec![]),
            }))
            // Routes outside the Bearer token middleware
            .service(index)
            .service(basic_auth)
            .service(create_user)
            .service(
              // Define a new scope to add middleware
              // This string is located in between the base (http://localhost:8080/) and your endpoints (/create-todolist-entry, /get-todolist-entries, etc.)
              // Ex. http://localhost:8080/v1/create-todolist-entry
              web::scope("") // http://localhost:8080/<endpoint name>
                // Utilize the Bearer middleware
                .wrap(bearer_middleware)
                // Routes requiring a Bearer token
                .configure(services::config),
            )
    })
    .bind(("localhost", 8080))?
    .run()
    .await
}`}
      />
      Now to create our auth module that we already imported. Create the
      following files:
      <br />
      <ul className="italic">
        <li>src/auth/mod.rs</li>
        <li>src/auth/models.rs</li>
        <li>src/auth/services.rs</li>
      </ul>
      <br />
      In our <span className="italic">src/auth/mod.rs</span> add the following:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={1}
        codeString={`pub mod models;
pub mod services;`}
      />
      In <span className="italic">src/auth/models.rs</span> we define our data
      structures:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={1}
        codeString={`use serde::{Deserialize, Serialize};
use uuid::Uuid;

#[derive(Serialize, Deserialize, Clone)]
pub struct User {
    pub id: Uuid,         // 123e4567-e89b-12d3-a456-426614174000
    pub username: String, // johndoe123
    pub password: String, // Hashed password123
}

#[derive(Deserialize)]
pub struct CreateUserBody {
    pub username: String, // johndoe123
    pub password: String, // password123
}
`}
      />
      In <span className="italic">src/auth/services.rs</span> we will create
      POST /user (create a user) and GET /login (create JWT from Basic auth).
      <br />
      First, our dependency imports:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={1}
        codeString={`// Actix Web
use actix_web::{
    get, post,
    web::{Data, Json},
    HttpResponse, Responder,
};

// Auth extractors
use actix_web_httpauth::extractors::basic::BasicAuth;

// JWT
use jwt::SignWithKey;

// Password hashing
use argonautica::{Hasher, Verifier};
use hmac::{Hmac, Mac};
use sha2::Sha256;

// Other
use uuid::Uuid;

// Structs from src/main.rs
use crate::{AppState, TokenClaims};

// Structs from src/auth/models.rs
use super::models::{CreateUserBody, User};`}
      />
      Next, we will define POST /user:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={28}
        codeString={`#[post("/user")]
async fn create_user(state: Data<AppState>, body: Json<CreateUserBody>) -> impl Responder {
    // Grab the body of the request
    let user: CreateUserBody = body.into_inner();

    // Hash the password
    let hash_secret = std::env::var("HASH_SECRET").expect("HASH_SECRET must be set!");
    let mut hasher = Hasher::default();
    let hashed_password = hasher
        .with_password(user.password)
        .with_secret_key(hash_secret)
        .hash()
        .unwrap();

    // Create a new user
    let new_user = User {
        id: Uuid::new_v4(),
        username: user.username,
        password: hashed_password,
    };

    // Add the user to the list of users
    state.users.lock().unwrap().push(new_user.clone());

    // Return the new user
    HttpResponse::Ok().json(new_user)
}`}
      />
      Finally, we define GET /login:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={56}
        codeString={`#[get("/login")]
async fn basic_auth(state: Data<AppState>, credentials: BasicAuth) -> impl Responder {
    // Grab the JWT secret from the .env file
    let jwt_secret: Hmac<Sha256> = Hmac::new_from_slice(
        std::env::var("JWT_SECRET")
            .expect("JWT_SECRET must be set!")
            .as_bytes(),
    )
    .unwrap();

    // Grab the username and password from the request's Authorization header
    let username = credentials.user_id();
    let password = credentials.password();

    match password {
        None => HttpResponse::Unauthorized().json("Must provide username and password"),
        Some(pass) => {
            // Find the user in the AppState
            match state
                .users
                .lock()
                .unwrap()
                .iter()
                .find(|user| user.username == username)
            {
                Some(user) => {
                    // Verify the password sent matches the hashed password
                    let hash_secret =
                        std::env::var("HASH_SECRET").expect("HASH_SECRET must be set!");
                    let mut verifier = Verifier::default();
                    let password_is_valid = verifier
                        .with_hash(&user.password)
                        .with_password(pass)
                        .with_secret_key(hash_secret)
                        .verify()
                        .unwrap();

                    if password_is_valid {
                        // If the password is valid, return a JWT
                        let claims = TokenClaims { id: user.id };
                        let token_str = claims.sign_with_key(&jwt_secret).unwrap();
                        HttpResponse::Ok().json(token_str)
                    } else {
                        // If the password is invalid, return an error
                        HttpResponse::Unauthorized().json("Incorrect password")
                    }
                }
                None => HttpResponse::Unauthorized().json("User does not exist"),
            }
        }
    }
}`}
      />
      We now have all the pieces necessary to create a user and store a securely
      hashed password, verify a user's credentials match a saved user, and
      create/consume JWTs for securing access to our API.
      <br />
      The last thing I'll show you is how to access the token information from a
      Bearer protected route. Here is an example route consuming the JWT:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[get("/todolist/entries")]
async fn get_entries(req_user: Option<ReqData<TokenClaims>>) -> impl Responder {
    match req_user {
        Some(user) => HttpResponse::Ok().json(format!("User: {}", user.id)),
        None => HttpResponse::Unauthorized().json("Unable to verify identity"),
    }
}`}
      />
      <ThunderClientPlug />
    </>
  );
};

export const blogListObject = {
  id: 3,
  title: "Securing your Actix Web API with Basic/Bearer authentication",
  formattedTitle:
    "securing-your-actix-web-api-with-basic-bearer-authentication",
  tags: ["Rust", "Actix Web", "Auth", "REST"].sort(),
  description:
    "Discover the essential steps to enhance the security of your Actix Web API in Rust by implementing both Basic and Bearer authentication mechanisms for robust protection against unauthorized access.",
  img: {
    src: IMG_SRC,
    alt: "A REST API server written with the rust programming language. Featuring a server processing many requests at once. The server is secured in a locked chest.",
  },
  createdAt: new Date("2023-12-01 16:47:21.309653+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-actix-web-auth",
  element: BlogPost,
};

export default BlogPost;
