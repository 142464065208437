import { signal, computed } from "@preact/signals-react";
import { default as blogPostsArr } from "../components/blogPosts";

export const blogPosts = signal(blogPostsArr);
export const tagsToFilterBy = signal([]);
export const selectedBlogPost = signal(null);
export const triggerRecordView = signal(false);
export const filteredBlogPosts = computed(() => {
  if (tagsToFilterBy.value.length > 0) {
    let filteredBlogPosts = blogPosts.value.filter((bp) => {
      let count = 0;

      for (let tag of tagsToFilterBy.value) {
        if (bp.tags.includes(tag.value)) count++;
      }

      if (count === tagsToFilterBy.value.length) return true;

      return false;
    });

    return filteredBlogPosts;
  }

  return blogPosts.value;
});
export const setTagsToFilterBy = (value) => (tagsToFilterBy.value = value);
export const setSelectedblogPost = (value) => (selectedBlogPost.value = value);
export const toggleTriggerRecordView = () =>
  (triggerRecordView.value = !triggerRecordView.value);
