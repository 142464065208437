import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";

const SignOff = ({ repoUrl }) => {
  return (
    <>
      <br />
      <br />
      Full code can be found at{" "}
      <LinkToExternalSource href={repoUrl}>{repoUrl}</LinkToExternalSource>.
    </>
  );
};

export default SignOff;
