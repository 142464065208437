import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import IMG_SRC from "../../assets/1/1.webp";
import ThunderClientPlug from "../plugs/ThunderClient";

const BlogPost = () => {
  return (
    <>
      Today we're going to create a Todolist REST API using the{" "}
      <LinkToExternalSource href="https://actix.rs/">
        Actix Web
      </LinkToExternalSource>{" "}
      framework in Rust. This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />
      First, we need to create a new project. In your terminal run:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="&gt; cargo new todolist-api "
      />
      Next we need to add our dependencies:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="&gt; cd todolist-api "
      />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="&gt; cargo add actix-web serde_json serde --features=serde/derive "
      />
      The <code className="inline">actix-web</code> dependency is the Actix Web
      framework. We will be using <code className="inline">serde</code> for
      serializing/deserializing JSON.
      <br />
      <br />
      Our <span className="italic">Cargo.toml</span> file should now look
      something like this:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers
        codeString={`[package]
name = "todolist-api"
version = "0.1.0"
edition = "2021"

# See more keys and their definitions at https://doc.rust-lang.org/cargo/reference/manifest.html

[dependencies]
actix-web = "4.4.0"
serde = { version = "1.0.193", features = ["derive"] }
serde_json = "1.0.108"
`}
      />
      Next, let's setup the http server. Open your{" "}
      <span className="italic">src/main.rs</span> file and add your dependency
      imports:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        codeString={`use actix_web::{get, web::Data, App, HttpServer, Responder, HttpResponse};
use serde::{Deserialize, Serialize};
use std::sync::Mutex;`}
      />
      The <code className="inline">web::Data</code> struct allows you to create
      a shared app state. This is useful for something like a DB connection
      pool. For our purposes, we'll use a simple vector to store our todolist
      entries. Let's define that now.
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={5}
        codeString={`struct AppState {
    todolist_entries: Mutex<Vec<TodolistEntry>>,
}`}
      />
      And our <code className="inline">TodolistEntry</code> struct definition:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={9}
        codeString={`#[derive(Serialize, Deserialize, Clone)]
struct TodolistEntry {
    id: i32,
    date: i64,
    title: String,
}`}
      />
      Now to actually build our Http server:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={16}
        codeString={`// This tells our program to utilize the actix_web runtime
#[actix_web::main]
async fn main() -> std::io::Result<()> {
    let app_data: Data<AppState> = Data::new(AppState {
        todolist_entries: Mutex::new(vec![])
    });

    HttpServer::new(move || {
        App::new()
            .app_data(app_data.clone())
    })
    .bind(("localhost", 8080))?
    .run()
    .await
}`}
      />
      Currently, if we run <code className="inline">cargo r -r</code> the server
      should be running successfully. However, if we open
      "http://localhost:8080" in our browser, we will receive a 404. This is
      because we don't have any routes yet! Let's build a quick health check
      route in <span className="italic">src/main.rs</span>:<br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={16}
        codeString={`
#[get("/")] // GET method for the "/" path
async fn index() -> impl Responder {
    HttpResponse::Ok().json("{ status: OK }")
}
`}
      />
      And in our HttpServer builder we'll tell our server to use it:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={31}
        codeString={`.app_data(app_data.clone())
.service(index)`}
      />
      Let's restart our server. In your terminal press{" "}
      <code className="inline">Ctrl + C</code> and run{" "}
      <code className="inline">cargo r -r</code> again.
      <br />
      This time if we navigate to "http://localhost:8080" in our browser, we
      should see <code className="inline">{`"{ status: OK }"`}</code>.<br />
      <br />
      Now that we have a basic server up and running, let's build the CRUD
      routes for our todolist.
      <br />
      Create a new directory to hold our todolist specific routes:
      <br />
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString="&gt; mkdir src/todolist "
      />
      Create the following files:
      <br />
      <ul className="italic">
        <li>src/todolist/mod.rs</li>
        <li>src/todolist/models.rs</li>
        <li>src/todolist/services.rs</li>
      </ul>
      In <span className="italic">src/todolist/mod.rs</span> add the following
      lines to define the todolist module:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        codeString={`pub mod services; // Used externally
mod models; // Used internally`}
      />
      In <span className="italic">src/todolist/models.rs</span> let's define the
      body shapes for our POST and PUT routes:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        codeString={`use serde::Deserialize;

#[derive(Deserialize)]
pub struct CreateEntryBody {
    pub title: String,
    pub date: i64
}

#[derive(Deserialize)]
pub struct UpdateEntryBody {
    pub title: String
}`}
      />
      Now for the todolist routes in{" "}
      <span className="italic">src/todolist/services.rs</span>.<br />
      First our imports:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        codeString={`use actix_web::{get, post, put, delete, web, Responder, HttpResponse};
use crate::AppState;
use crate::TodolistEntry;
use super::models::{CreateEntryBody, UpdateEntryBody};`}
      />
      Next a GET route to fetch all todolist entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={6}
        codeString={`#[get("/todolist/entries")]
async fn get_entries(data: web::Data<AppState>) -> impl Responder {
    HttpResponse::Ok().json(data.todolist_entries.lock().unwrap().to_vec())
}`}
      />
      A POST route to create new entries, returning the updated list of entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={11}
        codeString={`#[post("/todolist/entries")]
async fn create_entry(data: web::Data<AppState>, body: web::Json<CreateEntryBody>) -> impl Responder {
    let mut todolist_entries = data.todolist_entries.lock().unwrap();
    let max_id = todolist_entries.iter().fold(0, |acc, x| if x.id > acc { x.id } else { acc });
    todolist_entries.push(TodolistEntry {
        id: max_id + 1,
        title: body.title.clone(),
        date: body.date
    });

    HttpResponse::Ok().json(todolist_entries.to_vec())
}`}
      />
      A PUT route for updating the title of an entry, returning the updated list
      of entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={24}
        codeString={`#[put("/todolist/entries/{id}")]
async fn update_entry(data: web::Data<AppState>, path: web::Path<i32>, body: web::Json<UpdateEntryBody>) -> impl Responder {
    let id = path.into_inner();
    let mut todolist_entries = data.todolist_entries.lock().unwrap();
    for i in 0..todolist_entries.len() {
        if todolist_entries[i].id == id {
            todolist_entries[i].title = body.title.clone();
            break;
        }
    }

    HttpResponse::Ok().json(todolist_entries.to_vec())
}`}
      />
      A DELETE route for removing an entry from our list of todos, returning the
      updated list of entries:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={38}
        codeString={`#[delete("/todolist/entries/{id}")]
async fn delete_entry(data: web::Data<AppState>, path: web::Path<i32>) -> impl Responder {
    let mut todolist_entries = data.todolist_entries.lock().unwrap();

    let id = path.into_inner();
    *todolist_entries = todolist_entries.to_vec().into_iter().filter(|x| x.id != id).collect();

    HttpResponse::Ok().json(todolist_entries.to_vec())
}`}
      />
      The last thing we're adding to the{" "}
      <span className="italic">src/todolist/services.rs</span> file will keep
      our <span className="italic">src/main.rs</span> file a little bit cleaner
      by handling the route registration from here:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={48}
        codeString={`pub fn config(cfg: &mut web::ServiceConfig) {
    cfg
        .service(get_entries)
        .service(create_entry)
        .service(update_entry)
        .service(delete_entry);
}`}
      />
      Before we can run our server and access the routes we've just created, we
      need to add them to our HttpServer builder. Back in our{" "}
      <span className="italic">src/main.rs</span> add the following lines:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={5}
        codeString={`mod todolist;
use todolist::services;`}
      />
      <CodeSnippet
        language="rust"
        showLineNumbers
        startingLineNumber={34}
        codeString={`.service(index)
.configure(services::config)`}
      />
      <ThunderClientPlug />
    </>
  );
};

export const blogListObject = {
  id: 1,
  title: "Fast REST API with Rust and Actix Web",
  formattedTitle: "fast-rest-api-rust-actix-web",
  tags: ["Rust", "Actix Web", "REST"].sort(),
  description: `Creating a REST API with Rust and the Actix Web framework is a breeze.
        In this tutorial, we're going to walk through setting up your project and creating your first CRUD routes.`,
  img: {
    src: IMG_SRC,
    alt: "A REST API server written with the rust programming language. Featuring a server processing many requests at once.",
  },
  createdAt: new Date("2023-11-25 15:09:00.269273+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-fast-rest-api-rust-actix-web",
  element: BlogPost,
};

export default BlogPost;
