import { tagColors } from "../../constants";
import "./styles.css";

const Tag = ({ name }) => {
  return (
    <span className="tag" style={{ background: tagColors[name] }}>
      {name}
    </span>
  );
};

export default Tag;
