import CodeSnippet from "../codeSnippet/CodeSnippet";
import LinkToExternalSource from "../linkToExternalSource/LinkToExternalSource";
import SWAGGER_DOCUMENT_BONES from "../../assets/4/swagger-document-bones.webp";
import SWAGGER_DOCUMENT_SCHEMA from "../../assets/4/swagger-document-schema.webp";
import SWAGGER_DOCUMENT_ENDPOINT from "../../assets/4/swagger-document-endpoint.webp";
import SWAGGER_DOCUMENT_EXAMPLE_VALUES from "../../assets/4/swagger-document-example-values.webp";
import SWAGGER_DOCUMENT_EXAMPLE_SCHEMA from "../../assets/4/swagger-document-example-schema.webp";
import IMG_SRC from "../../assets/4/4.webp";
import ThunderClientPlug from "../plugs/ThunderClient";

const BlogPost = () => {
  return (
    <>
      Today we're going to add basic/bearer auth to an existing{" "}
      <LinkToExternalSource href="https://actix.rs/">
        Actix Web
      </LinkToExternalSource>{" "}
      REST API.
      <br />
      This tutorial assumes you have{" "}
      <LinkToExternalSource href="https://www.rust-lang.org/tools/install">
        Rust installed
      </LinkToExternalSource>{" "}
      already.
      <br />
      <br />I will be using a basic Actix Web REST API I've built. Here is the
      source code:{" "}
      <LinkToExternalSource href="https://github.com/bocksdin/blog-basic-actix-web-api">
        https://github.com/bocksdin/blog-basic-actix-web-api
      </LinkToExternalSource>
      .<br />
      To learn how to setup an Actix Web REST API, check out my previous
      article:{" "}
      <LinkToExternalSource href="https://bocksdincoding.com/blog/fast-rest-api-rust-actix-web">
        Fast REST API with Rust and Actix Web
      </LinkToExternalSource>
      .<br />
      <br />
      First, we need our dependency crates:{" "}
      <code className="inline">utoipa</code> and{" "}
      <code className="inline">utoipa-swagger-ui</code>.
      <CodeSnippet
        language="text"
        showLineNumbers={false}
        codeString={`> cargo add utoipa utoipa-swagger-ui --features="utoipa/actix_extras utoipa/chrono utoipa-swagger-ui/actix-web" `}
      />
      The utoipa/chrono feature is only necessary if your data structures
      utilize chrono types.
      <br />
      <br />
      In our <span className="italic">src/main.rs</span> let's import our
      dependencies:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`use utoipa::OpenApi;
use utoipa_swagger_ui::SwaggerUi;`}
      />
      In order to configure Swagger for our API, we need to build an OpenApi
      document and serve it at a specified url. In our main function, before we
      build our HttpServer instance, we need to create our OpenApi document:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(OpenApi)]
#[openapi()]
struct ApiDoc;

let openapi = ApiDoc::openapi();`}
      />
      And then create a new service for our documentation:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`.service(SwaggerUi::new("/swagger-ui/{_:.*}").url(
  "/api-docs/openapi.json",
  openapi.clone()
))`}
      />
      At this point, if we run our server and go to
      "http://localhost:8080/swagger-ui/", we will see the Swagger documentation
      framework.
      <br />
      <img
        className="reference"
        src={SWAGGER_DOCUMENT_BONES}
        alt="Swagger document with no API endpoints defined."
      />
      <br />
      There isn't much to see since we haven't defined any routes. Let's start
      by documenting a simple health check route we have:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[get("/")] // GET method for the "/" path
async fn index() -> impl Responder {
    HttpResponse::Ok().json("{ status: OK }")
}`}
      />
      We'll first convert this to return a simple struct with a status property
      and a number, such as 200.
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`HttpResponse::Ok().json(SimpleStruct { status: 200 })`}
      />
      <code className="inline">SimpleStruct</code> defined as such:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(serde::Serialize, utoipa::ToSchema)]
struct SimpleStatus {
    status: u16
}`}
      />
      Here, the <code className="inline">utoipa::ToSchema</code> trait allows a
      Swagger definition to be automatically created for the struct. This makes
      it super handy to always have your documentation up to date.
      <br />
      Now to create a endpoint definition for the GET route:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[utoipa::path(
  responses(
      (status = 200, description = "API is alive and well!", body = SimpleStatus)
  )
)]
#[get("/")] // GET method for the "/" path
async fn index() -> impl Responder {
  HttpResponse::Ok().json(SimpleStatus { status: 200 })
}`}
      />
      Include the endpoint definition and SimpleStatus definition in our OpenApi
      document:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[openapi(
    paths(
        index,
    ),
    components(
        schemas(
            SimpleStatus,
        ),
    )
)]`}
      />
      And now if we go to "http://localhost:8080/swagger-ui/" in our browser, we
      should see the following:
      <br />
      <img
        className="reference"
        src={SWAGGER_DOCUMENT_SCHEMA}
        alt="Swagger document with the SimpleStruct type definition."
      />
      <img
        className="reference"
        src={SWAGGER_DOCUMENT_ENDPOINT}
        alt="Swagger document with the endpoint defintion for the health check route."
      />
      If you hit the "Try it out" button, and then the "Execute" button, you
      should see the API return a 200 with an object containing a status
      property with a value of 200 as well.
      <br />
      <br />
      This was a simple example. Let's see some additional examples describing
      some typical endpoint use-cases:
      <br />
      <br />
      Let's say our endpoint can return one of a few different status codes we
      specify.
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[utoipa::path(
  responses(
    (status = 200, description = "Happy Path", body = SimpleStruct),
    (status = 400, description = "Missing information"),
    (status = 401, description = "Unauthorized user"),
    (status = 403, description = "Feature not turned on"),
    (status = 500, description = "Internal Server Error"),
  )
)]`}
      />
      Maybe our endpoint expects a request body containing information upon
      which to act:
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[utoipa::path(
  request_body = CreateTodolistEntry,
  responses( ... )
)]`}
      />
      Perhaps our endpoint has a param in the path:
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(Deserialize, IntoParams)]
struct ExpectedPathParams {
  id: i32
}

#[utoipa::path(
  params(ExpectedPathParams),
  request_body = UpdateTodolistEntry,
  responses( ... )
)]
#[put("/todolist-entries/{id})]`}
      />
      <br />
      Another common setup is basic and bearer authentication to secure routes.
      That requires an initial basic configuration of our{" "}
      <code className="inline">ApiDoc</code> struct, but then protecting routes
      is super easy.
      <br />
      Import the following dependencies:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`use utoipa::{Modify, openapi::security::{HttpAuthScheme, HttpBuilder, SecurityScheme}}`}
      />
      Then, in our <code className="inline">main</code> function, define our
      basic and bearer auth schemes to apply to our ApiDoc:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`struct SecurityModifier;
impl Modify for SecurityModifier {
    fn modify(&self, openapi: &mut utoipa::openapi::OpenApi) {
        let components = openapi.components.as_mut().unwrap();

        // Define a scheme for bearer auth utilizing the Authorization header for "Bearer <json web token>"
        components.add_security_scheme(
            "bearer_auth", // This can be named whatever you'd like
            SecurityScheme::Http(
                HttpBuilder::new()
                    .scheme(HttpAuthScheme::Bearer)
                    .bearer_format("JWT")
                    .build(),
            ),
        );

        // Define a scheme for basic auth utilizing the Authorization header for "Basic <base64 encoded credentials>"
        components.add_security_scheme(
            "basic_auth", // This can be named whatever you'd like
            SecurityScheme::Http(HttpBuilder::new().scheme(HttpAuthScheme::Basic).build()),
        );
    }
}`}
      />
      And finally add it as a modifier to our{" "}
      <code className="inline">ApiDoc</code> struct:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(OpenApi)]
#[openapi(
    paths(
        index,
    ),
    components(
        schemas(
            SimpleStatus,
        ),
    ),
    modifiers(&SecurityModifier)
)]
struct ApiDoc;`}
      />
      Using it in our endpoint definitions is a breeze. Take for instance,
      adding it to our health check endpoint:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[utoipa::path(
  responses(
      (status = 200, description = "API is alive and well!", body = SimpleStatus)
  ),
  security(
    ("bearer_auth" = []) // Or "basic_auth" for basic auth
  )
)]`}
      />
      <br />
      The last thing I'm going to show you is how to provide a bit of extra
      definition for your data types.
      <br />
      Take, for instance, this request body structure for leaving a review for a
      restaurant:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(Deserialize, ToSchema)]
struct CreateRestaurantReviewBody {
  restaurant_id: u32,
  menu_item: String,
  review_description: String,
  rating: u8,
  would_recommend: Option<bool>
}`}
      />
      We can provide examples of expected values, and specify which fields are
      required:
      <br />
      <CodeSnippet
        language="rust"
        showLineNumbers={false}
        codeString={`#[derive(Deserialize, ToSchema)]
struct CreateRestaurantReviewBody {
  #[schema(example = 13123, required = true)]
  restaurant_id: u32,
  #[schema(example = "Eggs Benedict", required = false)]
  menu_item: Option<String>,
  #[schema(example = "The restaurant was clean and the staff were helpful!", required = true)]
  review_description: String,
  #[schema(example = 5, required = true)]
  rating: u8,
  #[schema(example = true, required = true)]
  would_recommend: Option<bool>
}`}
      />
      Now when we pull up our swagger documentation, you can see the example
      values are provided and required fields are flagged accordingly:
      <br />
      <img
        className="reference"
        src={SWAGGER_DOCUMENT_EXAMPLE_VALUES}
        alt="Swagger document showing a request body definition for the restaurant review route with example values we provided."
      />
      <img
        className="reference"
        src={SWAGGER_DOCUMENT_EXAMPLE_SCHEMA}
        alt="Swagger document showing a request body schema for the restaurant review route with example values, field data types, and required flags."
      />
      <br />
      These basics should cover most needs, but there are additional
      configurations that can be made. I invite you to discover those on your
      own:{" "}
      <LinkToExternalSource href="https://docs.rs/utoipa/latest/utoipa/">
        https://docs.rs/utoipa/latest/utoipa/
      </LinkToExternalSource>
      .
      <ThunderClientPlug />
    </>
  );
};

export const blogListObject = {
  id: 4,
  title: "API Documentation made easy with Swagger",
  formattedTitle: "api-documentation-made-easy-with-swagger",
  tags: ["Rust", "Actix Web", "REST"],
  description:
    "Solid documentation is key when collaborating with other developers. Easily describe your API endpoints and data types using Swagger.",
  img: {
    src: IMG_SRC,
    alt: "A REST API server written with the rust programming language. Featuring a server processing many requests at once. An engineer stands at a terminal reading the documentation for the server's endpoints.",
  },
  createdAt: new Date("2023-12-08 16:57:57.515384+00").toLocaleDateString(),
  repoUrl: "https://github.com/bocksdin/blog-actix-web-swagger",
  element: BlogPost,
};

export default BlogPost;
